<!--
 * @Descripttion: 产品列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-27 14:11:47
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-07-25 14:50:29
-->
<template>
    <div class="productList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">产品列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="select-item">
                        <el-select filterable clearable v-model="type_id" placeholder="请选择系列筛选" @change="clickTypeChange">
                            <el-option v-for="item in typeOptions" :key="item.type_id" :label="item.name"
                                :value="item.type_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select filterable clearable v-model="size_id" placeholder="请选择尺寸筛选" @change="clickSizeChange">
                            <el-option v-for="item in sizeOptions" :key="item.size_id" :label="item.name"
                                :value="item.size_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select filterable clearable v-model="scene_id" placeholder="请选择使用场景筛选"
                            @change="clickSceneChange">
                            <el-option v-for="item in sceneOptions" :key="item.scene_id" :label="item.name"
                                :value="item.scene_id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="clickAdd" type="primary">添加产品</el-button>
                </div>
            </div>


            <div class="table-wrap" v-loading="loading">
                <el-table ref="tableData" :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="contents" label="产品图片" width="140" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <img :src="scope.row.contents[0]" height="50" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="产品名称"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="model" label="产品型号"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="type_transform" label="系列"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="size_transform" label="规格"></el-table-column>
                    <el-table-column align="center" prop="space_transform" label="场景"
                        :show-overflow-tooltip="true"></el-table-column>
                    <!-- <el-table-column align="center" label="上下架">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.is_lowerup" active-value="1" inactive-value="0" @change="changeLowerup(scope.row)"
                                active-color="#13ce66" inactive-color="#eeeeee">
                            </el-switch>
                            <label>{{scope.row.is_lowerup}}</label>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="特价">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.is_specialoffer" @change="changeSpecialoffer(scope.row)"
                                active-color="#13ce66" inactive-color="#eeeeee">
                            </el-switch>
                            <label>{{scope.row.is_specialoffer}}</label>
                        </template>
                    </el-table-column> -->
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="handeClickEdit(scope.row)">编辑</el-button>
                            <el-button type="danger" size="mini" @click="handeClickDel(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
                        :page-size="currentPageSize" layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>

        </div>

        <!-- 添加、编辑弹窗 -->
        <el-dialog :before-close="handleClose" :close-on-click-modal="false" :visible.sync="dialog_handle_add"
            custom-class="dialog-wrap">
            <div class="title">添加/编辑产品</div>
            <div class="content-wrap">
                <el-form :model="FormEdit" ref="FormEdit" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="产品名称">
                        <el-input style="width: 220px" v-model="FormEdit.name"></el-input>
                    </el-form-item>

                    <el-form-item label="型号" required>
                        <el-input style="width: 220px" v-model="FormEdit.model"></el-input>
                    </el-form-item>

                    <el-form-item label="品类" required>
                        <el-select v-model="FormEdit.categoryid">
                            <el-option style="width: 220px" v-for="(item, i) of addCategoryOptions"
                                :value="item.category_id" :label="item.name" :key='i'></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="系列" required>
                        <el-select v-model="FormEdit.typeid">
                            <el-option style="width: 220px" v-for="(item1, i1) of addtypeOptions" :value="item1.type_id"
                                :label="item1.name" :key='i1'></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="空间" required>
                        <el-select multiple v-model="FormEdit.spaceids">
                            <el-option style="width: 220px" v-for="(item2, i2) of addSpaceOptions" :value="item2.space_id"
                                :label="item2.name" :key='i2'></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="用途" required>
                        <el-select multiple v-model="FormEdit.purposeids">
                            <el-option style="width: 220px" v-for="(item3, i3) of addPurposeOptions"
                                :value="item3.purpose_id" :label="item3.name" :key='i3'></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="规格" required>
                        <el-select multiple v-model="FormEdit.sizeids">
                            <el-option style="width: 220px" v-for="(item4, i4) of addsizeOptions" :value="item4.size_id"
                                :label="item4.name" :key='i4'></el-option>
                        </el-select>
                    </el-form-item>




                    <el-form-item label="产品主图" required>
                        <div class="upload-wrap">
                            <aliyun-upload :limitSize="8" :limit="20" :isShopTip="false" multiple
                                :btnDisplay="bigfileList1.length < 20" listType="picture-card" v-model="bigfileList1">
                            </aliyun-upload>

                            <draggable v-model="bigfileList1" class="box1">
                                <div v-for="(item, i1) of bigfileList1" :key="i1" class="fileList1-box">
                                    <div @click="delfileList1(item, i1)" class="delFile">x</div>
                                    <el-image fit="contain" class="pic" :preview-src-list="bigfileList1" :src="item" />
                                </div>
                            </draggable><br>
                        </div>
                    </el-form-item>

                    <el-form-item label="产品详情图" required>
                        <div class="upload-wrap">
                            <aliyun-upload :limitSize="8" :limit="30" :isShopTip="false" multiple
                                :btnDisplay="bigfileList2.length < 30" listType="picture-card" v-model="bigfileList2">
                            </aliyun-upload>


                            <draggable v-model="bigfileList2" class="box1">
                                <div v-for="(item, i2) of bigfileList2" :key="i2" class="fileList1-box">
                                    <div @click="delfileList2(item, i2)" class="delFile">x</div>
                                    <el-image fit="contain" class="pic" :src="item" :preview-src-list="bigfileList2" />
                                </div>
                            </draggable><br>
                        </div>
                    </el-form-item>

                    <div class="btn-wrap">
                        <el-button :loading="btnLoading" type="primary" @click="submitForm">保存</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import draggable from "vuedraggable"
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components: {
        AliyunUpload,
        draggable,
    },
    mixins: [pageMixin],
    data() {
        return {
            dialog_handle_add: false,
            keyword: '',
            loading: false,
            tableData: [],
            currentPage: 1,
            currentPageSize: 10,
            total: 0,
            type_id: '',
            size_id: '',
            scene_id: '',
            specialoffer: '',
            lowerup: '',

            typeOptions: [],
            sizeOptions: [],
            sceneOptions: [],

            addtypeOptions: [],
            addSpaceOptions: [],
            addPurposeOptions: [],
            addsizeOptions: [],
            addCategoryOptions: [],

            btnLoading: false,
            FormEdit: {
                name: '',
                model: '',
                typeid: '',
                categoryid: '',
                purposeids: [],
                spaceids: [],
                sizeids: []
            },
            bigfileList1: [],
            bigfileList2: [],

            update_id: '',
        }
    },
    mounted() {
        this.getGoodsList();
        this.getTypeGoodsList();
        this.getSizeGoodsList();
        this.getSceneGoodsList();
        this.getInitAdd();
    },
    methods: {
        clickAdd() {
            this.clearAddData();
            this.dialog_handle_add = true
        },
        // 初始化添加
        getInitAdd() {
            this.loading = true;
            common.connect('/distributorpcv1/goods/initAddGoods', { put_on: true }, (res) => {
                // console.log(res);
                this.addtypeOptions = res.data.addtypeOptions;
                this.addSpaceOptions = res.data.addSpaceOptions;
                this.addPurposeOptions = res.data.addPurposeOptions;
                this.addsizeOptions = res.data.addsizeOptions;
                this.addCategoryOptions = res.data.addCategoryOptions;

                this.loading = false;
            })
        },
        clickSearch() {
            this.currentPage = 1;
            this.getGoodsList();
        },
        delfileList2(item, i) {
            this.bigfileList2.splice(i, 1)
        },
        delfileList1(item, i) {
            this.bigfileList1.splice(i, 1)
        },
        //清空
        clearAddData() {
            this.FormEdit = {
                name: '',
                model: '',
                typeid: '',
                categoryid: '',
                purposeids: [],
                spaceids: [],
                sizeids: []
            };
            this.bigfileList1 = [];
            this.bigfileList2 = [];
            this.update_id = '';
        },
        //保存
        submitForm() {
            this.FormEdit.purpose = []
            this.FormEdit.space = []
            this.FormEdit.size = []
            this.FormEdit.contents = []
            this.FormEdit.details_imgs = []

            if (this.FormEdit.purposeid != '') {
                this.FormEdit.purpose.push(JSON.stringify(this.FormEdit.purposeids))
            }
            if (this.FormEdit.spaceid != '') {
                this.FormEdit.space.push(JSON.stringify(this.FormEdit.spaceids))
            }
            if (this.FormEdit.sizeid != '') {
                this.FormEdit.size.push(JSON.stringify(this.FormEdit.sizeids))
            }
            if (this.FormEdit.categoryid != '') {
                this.FormEdit.category = this.FormEdit.categoryid
            }
            if (this.FormEdit.typeid != '') {
                this.FormEdit.type_id = this.FormEdit.typeid
            }

            if (Array.isArray(this.bigfileList1) && this.bigfileList1.length > 0) {
                this.FormEdit.contents = JSON.stringify(this.bigfileList1)
            }

            if (Array.isArray(this.bigfileList2) && this.bigfileList2.length > 0) {
                this.FormEdit.details_imgs = JSON.stringify(this.bigfileList2)
            }

            let v_FormEdit = this.FormEdit;

            if (!v_FormEdit.model) {
                this.$message.error('产品编号不能为空');
                return false
            }
            if (v_FormEdit.name === "") {
                this.$message.error('产品名称不能为空');
                return false
            }


            if (!v_FormEdit.type_id) {
                this.$message.error('产品类型不能为空');
                return false
            }
            if (!v_FormEdit.size.length) {
                // console.log(FormEdit);
                this.$message.error('产品规格不能为空');
                return false
            }
            if (!v_FormEdit.space.length) {
                this.$message.error('产品空间不能为空');
                return false
            }
            if (!v_FormEdit.purpose.length) {
                this.$message.error('产品用途不能为空');
                return false
            }


            if (this.bigfileList1.length == 0) {
                this.$message.error('产品主图不能为空');
                return false
            }
            if (this.bigfileList2.length == 0) {
                this.$message.error('产品详情图不能为空');
                return false
            }

            // console.log(v_FormEdit);
            // console.log(this.FormEdit);
            // console.log(this.update_id);

            if (!this.update_id) {
                console.log("添加");
                common.connect('/distributorpcv1/goods/setShopGoodsAdd', v_FormEdit, (res) => {
                    if (res.isSuccess == 1) {
                        this.dialog_handle_add = false;
                        this.btnLoading = false
                        this.clearAddData();
                        this.getGoodsList();
                    } else {
                        this.$message.error(res.msg);
                        this.btnLoading = false
                    }
                })
            } else {
                console.log("修改");
                v_FormEdit.id = this.update_id;
                common.connect('/distributorpcv1/goods/setShopGoodsUpdate', v_FormEdit, (res) => {
                    if (res.isSuccess == 1) {
                        this.dialog_handle_add = false;
                        this.btnLoading = false
                        this.clearAddData();
                        this.getGoodsList();
                    } else {
                        this.$message.error(res.msg);
                        this.btnLoading = false
                    }
                })
            }


        },
        //删除
        handeClickDel(row) {
            this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/goods/setShopGoodsDel', { id: row.id }, (res) => {
                    if (res.isSuccess == 1) {
                        this.getGoodsList();
                        this.$message.success('删除成功！')
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        //编辑
        handeClickEdit(row) {
            // console.log(row);
            // this.clearAddData();
            this.dialog_handle_add = true

            this.update_id = row.id;

            this.FormEdit.name = row.name;
            this.FormEdit.model = row.model;
            this.FormEdit.typeid = row.type_id;
            this.FormEdit.categoryid = row.category;

            this.FormEdit.purposeids = JSON.parse(row.purpose);
            this.FormEdit.spaceids = JSON.parse(row.space);
            this.FormEdit.sizeids = JSON.parse(row.size);

            this.bigfileList1 = row.contents;
            this.bigfileList2 = JSON.parse(row.details_imgs);
        },
        //上下架
        changeLowerup(row) {
            console.log(row);
        },
        //特价
        changeSpecialoffer(row) {
            console.log(row);
        },
        handleClose() {
            this.dialog_handle_add = false
        },
        // 使用场景筛选
        clickSceneChange() {
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 尺寸筛选
        clickSizeChange() {
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 系列筛选
        clickTypeChange() {
            this.currentPage = 1;
            this.getGoodsList();
        },
        // 获取数据列表
        getGoodsList() {
            let params = {
                page: this.currentPage,
                page_size: this.currentPageSize,
                keyword: this.keyword,
                type_id: this.type_id,
                size_id: this.size_id,
                scene_id: this.scene_id,
                specialoffer: this.specialoffer,
                lowerup: this.lowerup
            }
            // this.loading = true;
            common.connect('/distributorpcv1/goods/getShopGoodsList', params, (res) => {
                res.data.list.forEach(v => {
                    v.contents = JSON.parse(v.contents);
                    v.space_transform = v.space_transform.join(',');
                    v.type_transform = v.type_transform.join(',');
                    v.size_transform = v.size_transform.join(',')
                });
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 获取产品系列
        getTypeGoodsList() {
            common.connect('/distributorpcv1/goods/typeConditions', { put_on: true }, (res) => {
                this.typeOptions = res.data;
            })
        },
        // 获取产品尺寸
        getSizeGoodsList() {
            common.connect('/distributorpcv1/goods/sizeConditions', { put_on: true }, (res) => {
                this.sizeOptions = res.data;
            })
        },
        // 获取产品使用场景
        getSceneGoodsList() {
            common.connect('/distributorpcv1/goods/sceneConditions', { put_on: true }, (res) => {
                this.sceneOptions = res.data;
            })
        },
        //翻页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getGoodsList();
        }
    },
}
</script>

<style lang='scss'>
.productList {
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;

    .box1 {
        display: flex;
        flex-wrap: wrap;
        margin-left: 40px;
    }

    .fileList1-box {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        width: 110px;
        height: 110px;
        position: relative;
    }

    .pic {
        width: 110px;
        height: 110px;
    }

    .delFile {
        cursor: pointer;
        z-index: 992;
        position: absolute;
        font-size: 12px;
        line-height: 23px;
        text-align: center;
        width: 20px;
        height: 20px;
        right: 0px;
        top: 0px;
        background-color: #f4f4f4;
        border-radius: 15px;
    }

    .title-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;

        .tag {
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .case-wrap {
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;

        .screen-wrap {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .sw-left {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .select-item {
                    margin-right: 15px;
                    margin-bottom: 10px;
                }

                .search-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 10px;

                    .el-input {
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }

            .sw-right {
                width: 240px;
                margin-bottom: 10px;
                display: flex;
                justify-content: right;
                align-items: center;
            }
        }

        .table-wrap {
            .image {
                width: 40px;
                cursor: pointer;
            }

            .operation-wrap {
                display: flex;
                justify-content: center;

                .iconfont {
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }

    .main-body {
        display: none;
    }

    .dialog-wrap {
        width: 1200px;
        padding-left: 20px;
        overflow: hidden;
        position: relative;

        .title {
            font-size: 16px;
            color: #000;
            margin-top: -5px;
            padding-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .setting-dimensions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 30px;

                .sd-title {
                    font-size: 14px;
                    color: #333;
                    margin-right: 20px;
                }

                .tag {
                    padding: 0 10px;
                    font-size: 18px;
                    color: #000;
                }

                .sd-item {
                    display: flex;
                    align-items: center;

                    .uni {
                        font-size: 14px;
                        padding: 0 5px;
                    }

                    .unit {
                        font-size: 14px;
                        color: #666;
                        margin-right: 5px;
                    }

                    .el-input {
                        width: 50px;
                    }

                    .el-select__caret {
                        display: none;
                    }

                    .el-input__inner {
                        width: 50px;
                        height: 30px;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }

        .content-wrap {
            max-height: 80vh;
            overflow-y: auto;
            padding-bottom: 40px;
            padding-right: 20px;
            margin-top: -5px;

            .del-item {
                cursor: pointer;
                color: #f56c6c;
            }

            .btn-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }

            .upload-wrap {
                padding-top: 2px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .fileList-box {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 300px;

                    .box-list {
                        width: 100px;
                        height: auto;
                        position: relative;
                    }

                    .delFile {
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }

                    .pic {
                        width: 100%;
                    }
                }
            }

            .pagination-wrap {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 10px;
                position: absolute;
                bottom: 0;
                right: 10px;
                background: #fff;
                z-index: 99;
            }
        }
    }

    .el-dialog {
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 10px;
    }
}</style>

<template>
    <div class="aliyun-upload">
        <el-upload
            :class="btnDisplay?'':'HIDEUPBTN'"
            :limit="limit"
            :file-list = "tempFileList"
            :before-upload="initUploadData"
            :data="extData"
            :show-file-list="showFileList"
            :accept="accept"
            :multiple="multiple"
            :on-exceed="onExceed"
            :http-request="initAliOssAuthAndUpload"
            action
            :on-remove="onRemove"
            :list-type="listType">

            <i v-if="listType=='picture-card'" slot="default" class="el-icon-plus"></i>
            <el-button class="btn" v-if="listType!='picture-card'&&btnDisplay" type="primary">{{btnName}}</el-button>
            <div slot="tip" class="helper-text" v-if="isShopTip">
                只能上传
                {{accept.indexOf('image')!=-1?'jpg,jpeg,png,gif':""}}
                {{accept.indexOf('video')!=-1?'mp4,avi':""}}
                格式文件，且不超过{{limitSize}}M
                <span v-if="advicePx">，建议分辨率{{advicePx}}</span>
            </div>
        </el-upload>
    </div>
</template>
<script>
import common from "@/utils/common.js"
import axios from "axios";
let _loading = {};
export default {
    data(){
        return{
            extData:{},
            tempFileList:[],
        }
    },
    model:{
        prop: 'beenList',
        event: 'uploaded-list-change',
    },
    props:{
        btnName:{ //上传按钮名称
            type:String,
            default:"+ 上传"
        },
        advicePx:{ //分辨率建议
            type:String,
            default:""
        },
        limit:{ //上传张数限制
            type: Number,
            default: 1
        },
        accept:{ //格式类型，同input的accept属性
            type:String,
            default:"image/*"
        },
        showFileList:{ //是否显示文件列表
            type:Boolean,
            default:false
        },
        listType:{ //展示类型 picture 图片列表 picture-card 图片方块
            type:String,
            default:'picture'
        },

        limitSize:{ //单图大小限制
            type:Number,
            default:5
        },
        multiple:{ //是否开启多图上传
            type:Boolean,
            default:false
        },
        beenList:{ //使用v-model 相互绑定的映射对象.
            type:Array,
            default(){
            return []
            }
        },
        btnDisplay:{ //上传显示上传按钮
            type:Boolean,
            default:true
        },
        isShopTip:{ //上传显示上传按钮
            type:Boolean,
            default:true
        },
        sizePx:{  //上传是否规定尺寸  validWidth: 700,	validHeight: 240
            type: Boolean,
            default: false
        },
        heightAuto:{  //上传是否规定尺寸  validWidth: 700,	validHeight: 240
            type: Boolean,
            default: false
        },
        sizeVideo:{  //上传视频是否规定尺寸  
            
        },
        validWidth:{ //上传规定尺寸宽度
            type:Number,
            default:100
        },
        validHeight:{ //上传规定尺寸高度度
            type:Number,
            default:100
        },
        videoWidth:{ //上传视频规定尺寸宽度
            type:Number,
            default:100
        },
        videoHeight:{ //上传视频规定尺寸高度度
            type:Number,
            default:100
        },
        duration:{ //上传视频规定尺寸高度度
            type:Number,
            default:0
        },
        isAccept:{ //上传文件格式
            type: Boolean,
            default: false
        },
        acceptType:{ //上传文件格式类型
            type:String,
            default:''
        },
        isVideoType:{ //上传视频类型
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if(this.beenList&&this.beenList.length){
            this.tempFileList = this.beenList.map((r)=>{
                if(r.url){
                    return r;
                }
                else{
                    return {url:r}
                }
            });
        };
        this.$forceUpdate();
    },

    watch:{
        beenList(v){
            if(v.length<this.tempFileList.length){
                this.tempFileList = v.map((r)=>{
                    if(r.url){
                    return r;
                    }
                    else{
                    return {url:r}
                    }
                });
            }else if(v.length>this.tempFileList.length){
                this.tempFileList = this.beenList.map((r)=>{
                    if(r.url){
                    return r;
                    }
                    else{
                    return {url:r}
                    }
                });
            }
        }
    },
    methods:{
        onRemove(file,fileList){
            let list = null;
            if(this.limit==1||!this.multiple){
            list=[];
            }
            else{
            let wDelIndex = -1;
            list = this.beenList.filter((r)=>{
                if(r!=file.url){
                return true;
                }
                return false;
            });
            }
            this.$emit("uploaded-list-change",list);
        },
    async initAliOssAuthAndUpload(request){
        let file = request.file;
        let postData = new Promise((resolve, rej) => {
          // 判断签名有没有过期
          var res = JSON.parse(localStorage.getItem('sign'))
          var timestamp = Date.parse(new Date()) / 1000
          if (res && res.expire - 3 > timestamp) {
            resolve(res)
          } else {
            let stsUrl = common.apiServer+"/api/api/get_oss"
            axios.get(stsUrl).then(({data}) => {
              localStorage.setItem('sign', JSON.stringify(data.data))
              resolve(data.data)
            })
          }
        })
        await postData.then(success => {
            var data = success;
            var ossData = new FormData()
            //key就代表文件层级和阿里云上的文件名
            let filename = file.name
            ossData.append('key', this.autoFileName(file,data.dir));
            ossData.append('policy', data.policy)
            ossData.append('OSSAccessKeyId', data.accessid)
            ossData.append('success_action_status', 200)
            ossData.append('signature', data.signature)
            ossData.append('file', file,filename)
            axios.post(data.host, ossData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
                    _loading.text = '上传 ' +filename+" "+ complete
                }
            }).then(res => {
                if(res.status===200){
                    this.onSuccess(file);
                }
                }).catch(error => {
            })
        })
    },
    autoFileName(file,dir){
        let len = 15;
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = chars.length
        var pwd = ''
        for (let i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos))
        }
        let suffix = file.name.split(".");
        if(suffix.length>0){
          suffix = suffix[1];
        }
        else{
          suffix="";
        }
        file.url = dir+pwd+"."+suffix;
        return file.url;
    },
    onExceed(){
        this.$message.error('上传数量不能超过 '+this.limit);
    },
    onSuccess(file){
        setTimeout(function(){
          _loading.close();
        },500);
        file.url = "https://taocitong.oss-accelerate.aliyuncs.com/"+file.url;
        let list=[];
        if(this.limit==1||!this.multiple){
           list[0]=file.url;
        }
        else{
            this.tempFileList.push(file);
            list = [...this.beenList,file.url];
        }
        this.$emit('fileNamech',file.name)
        this.$emit("uploaded-list-change",list);
        this.$emit("uploaded",file.url);
        this.$forceUpdate();
      },
      initUploadData(file){
        _loading = this.$loading({text:"上传中"});
        const size = file.size / 1024 / 1024;
        const _this = this;

        if (size>this.limitSize) {
          this.$message.error('上传单个文件不能超过 '+this.limitSize+'MB!');
          _loading.close();
          return false;
        }
        // 限制图片的大小
        if(this.sizePx){
          if (this.validWidth && this.validHeight) {
            return this.valWidthAndHeight(file)
          }
        }
        if(this.heightAuto){
          if(this.validWidth && this.validHeight){
            return this.valHeight(file)
          }
        }
        // 限制视频的大小
        if(this.sizeVideo){
          if (this.videoWidth && this.videoHeight) {
            return this.validVideo(file)
          }
        }
        // 限制只能上传格式 
        if(this.isAccept){
            return this.validAccept(file);
        }
        // 上传视频获取时长
        if(this.isVideoType){
            return this.getVideoInfo(file);
        }
       return true;
    },
    // 获取视频信息
    getVideoInfo(file){
        let _this = this;
        return new Promise(function (resolve, reject) {
            let _URL = window.URL || window.webkitURL
            var video = document.createElement("video");
            video.src = _URL.createObjectURL(file)
            video.addEventListener("loadedmetadata",function() {    
                let videoInfo = {
                    duration: parseInt(video.duration),
                    size: parseInt(file.size / 1024)
                }
                _this.$emit('videoInfo',videoInfo)
                resolve()
            });
        }).then(() => {
            return file
        },() => {
            _loading.close();
            return Promise.reject()
        })
    },
    // 文件格式限制
    validAccept(file){
        let _this = this;
        return new Promise(function (resolve, reject) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            let acceptType = _this.acceptType;
            if(testmsg == acceptType){
                resolve();
            }else{
                reject();
            }
        }).then(() => {
            return file
        },() => {
            this.$message.error(`只能上传.${_this.acceptType}格式!`)
            _loading.close();
            return Promise.reject()
        })
    },
    // 视频校验
    validVideo(file){
        let _this = this;
        let isFlang;
        return new Promise(function (resolve, reject) {
            let width = _this.videoWidth// 视频宽度
            let height = _this.videoHeight// 视频高度
            let duration = _this.duration// 视频高度
            let _URL = window.URL || window.webkitURL
            var video = document.createElement("video");
            video.src = _URL.createObjectURL(file)
            video.addEventListener("loadedmetadata",function() {    
                let valid = parseInt(video.videoWidth) == width && parseInt(video.videoHeight) == height &&
                parseInt(video.duration) == duration;
                if(valid){
                resolve()
                }else{
                reject()
                isFlang = 2;
                }
                // valid ? resolve() : reject();
                if(parseInt(video.videoWidth) == width && parseInt(video.videoHeight) == height && parseInt(video.duration) != duration){
                isFlang = 1;
                }else if(parseInt(video.videoWidth) != width && parseInt(video.videoHeight) != height && parseInt(video.duration) == duration){
                isFlang = 2;
            }
        });
        }).then(() => {
            return file
        },() => {
            if(isFlang == 1){
                this.$message.error('上传的视频限时长' + _this.duration + '秒以内!')
            }else{
                this.$message.error('上传视频尺寸不符合,只能是' + _this.videoWidth + '*' + _this.videoHeight + '!')
            }
            _loading.close();
            return Promise.reject()
        })
    },
  // 验证图片宽,高区间控制
    valHeight (file) {
        let _this = this
        return new Promise(function (resolve, reject) {
            let width = _this.validWidth// 图片宽度
            let height = _this.validHeight// 图片高度
            let _URL = window.URL || window.webkitURL
            let image = new Image();
            image.src = _URL.createObjectURL(file)

            image.onload = function () {
                let valid = image.width == width && image.height >= height
                valid ? resolve() : reject()
            }
        }).then(() => {
            return file
        },() => {
            this.$message.error('上传图片尺寸不符合规定,图片宽为' + _this.validWidth + '，图片高度必须大于等于' + _this.validHeight + '!')
            _loading.close();
            return Promise.reject()
        })
    },
    // 验证图片宽高
    valWidthAndHeight (file) {
        let _this = this
        return new Promise(function (resolve, reject) {
            let width = _this.validWidth// 图片宽度
            let height = _this.validHeight// 图片高度
            let _URL = window.URL || window.webkitURL
            let image = new Image();
            image.src = _URL.createObjectURL(file)

            image.onload = function () {
                let valid = image.width == width && image.height == height
                valid ? resolve() : reject()
            }
        }).then(() => {
            return file
        },() => {
            this.$message.error('上传图片尺寸不符合,只能是' + _this.validWidth + '*' + _this.validHeight + '!')
            _loading.close();
            return Promise.reject()
        })
    },
}}
</script>

<style >
.HIDEUPBTN .el-upload--picture-card{
  display: none;
}
.aliyun-upload{
  line-height: 20px;
  font-size: 13px;

}
.aliyun-upload .el-upload-list__item-thumbnail{
    object-fit: contain;
}
.contanerFitImageView  .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    object-fit: contain;
}
.contanerCoverImageView .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    object-fit: cover;
}
.btn{
    margin-right: 10px;
}
</style>
